import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage4Avaleht from "./pages/Webpage4Avaleht";
import Webpage5Tingimused from "./pages/Webpage5Tingimused";
import Webpage6Terms from "./pages/Webpage6Terms";
import Webpage9K__psised from "./pages/Webpage9K__psised";
import Webpage10Tooted from "./pages/Webpage10Tooted";
import Webpage11Toode from "./pages/Webpage11Toode";
import Webpage12Tellimus from "./pages/Webpage12Tellimus";
import Webpage16Products from "./pages/Webpage16Products";
import Webpage17Product from "./pages/Webpage17Product";
import Webpage18Checkout from "./pages/Webpage18Checkout";
import Webpage19Home from "./pages/Webpage19Home";
import Webpage20Cookie_policy from "./pages/Webpage20Cookie_policy";
import Webpage22Page_not_found from "./pages/Webpage22Page_not_found";

var baseURL = 'https://lilleorukeskus.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiMmUyYjdlMDAyNTk5ZWRlNGYxMThkOWE4NjM0ZGI5ZTA3ODEwODUyMjY2YTgxMzBhNjgxYTI2MzAxMmMyM2ZlMWZhYTdhNDdhNjM4ZjE3NGMiLCJpYXQiOjE3MzM5MzY0NzkuNDEyOTgzLCJuYmYiOjE3MzM5MzY0NzkuNDEyOTg3LCJleHAiOjE3MzQwMjI4NzkuNDA5MjA4LCJzdWIiOiI2MCIsInNjb3BlcyI6W119.yIpmjHvR48ZMUR1GoEgwLfUlqYXX-M93mLP9-MjDRq9Fbqj8b4uaEAWQ6kGWNjfRiVHM6ug7kUBgzFIVPi7jAnkla2ODZ-ZoAy0v5oYWJ9BBemWi8ze6TXY8WlWLqeoB4lKYpDDrRTjAFMAHW56Z61AuBgpNPyVmETrX_cMBdIH50lCBXyVY-DSeGhORbnasOSibXWexfinCHaImFnJzH8aYB_8Do7D4QqjIsPCJwsIw1j0AVPYmKdTCU385oKmWQ3SPZwOcgBbdy4twpUztmdY7N-dqVd4JpNheBGy2QDpgBTXirRg2frUFeCa1qD_vktHiYl7mQE-0degCk9dRVUoiBrlpkubkP6KxWGbz1a29xn_O03mc-YocP4m6_kNPpvEWTQdy6zXEu5nHRFZnSfmk6nM5d-0jd3ghVW-siLuDp45B267x-c1KS2RRL5tzgX7YktX1kRMEJOfckd68_USMPZkCBY0KqNtik8QGuVZaO00lzBiTeREXfsPYp8eYk7xPIoDxO_lvc8VQOKKOrFfcY6vKd9Tr1O1JQvyFgj25Uos6c2MiD2QA3Z2C5gSY2fSVvNS5GuPvpazYbBJPN7Pf34sdfz7rNxgFLUGMZeb8Qb_K2RptjHF-DVSkUxywiQnPJX6viO4Mc4sKs5veD02o9C9By5BCOM1C0dRrTYM',
    'Access-api-key': "eyJpdiI6InNJd3lOczV2UnQ2VjFFWWFaZUI4THc9PSIsInZhbHVlIjoiVDhkaVF2Z2k0SGxIRFZJem1WUTlwdz09IiwibWFjIjoiMmY5YzVjNzg1YzE1MWMyMzYwNWIzYzNhOGYxMTlkOTc5NTU4M2MxZjY0NzkyOTQ2ZDQwOWVhNzlhZjQ4ZGY4ZiIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IkswNGgxUHEycTJFV2pWNDg5WVQ2MVE9PSIsInZhbHVlIjoiUWJ5Wkwwa0VZdUE4SVRwSHNJbUJFUT09IiwibWFjIjoiMWViZWVkZDIyNjFmNTRlODUzYzFiZGFkYTAyM2Q1MzNlZDA5Mzc3ZWIyYWU0ZDBlYzVmYWE2MWRlM2JmMWZlZiIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-WW9QW6"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage4Avaleht />} exact />;
                                                                    <Route path="/et/tingimused" element={<Webpage5Tingimused />} exact />;
                                                                    <Route path="/en/terms" element={<Webpage6Terms />} exact />;
                                                                    <Route path="/et/kupsised" element={<Webpage9K__psised />} exact />;
                                                                    <Route path="/et/tooted" element={<Webpage10Tooted />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage11Toode />} exact />;
                                                                    <Route path="/et/tellimus" element={<Webpage12Tellimus />} exact />;
                                                                    <Route path="/en/products" element={<Webpage16Products />} exact />;
                                                                    <Route path="/en/product/:id" element={<Webpage17Product />} exact />;
                                                                    <Route path="/en/checkout" element={<Webpage18Checkout />} exact />;
                                                                    <Route path="/en/" element={<Webpage19Home />} exact />;
                                                                    <Route path="/en/cookiepolicy" element={<Webpage20Cookie_policy />} exact />;
                                                                    <Route path="/et/*" element={<Webpage22Page_not_found />} exact />;
                                                <Route path="*" element={<Webpage22Page_not_found />} exact />;
                                                                
                                            <Route path="/toode/alladini-komplekt-4-klaasiga" element={<Redirect to="/toode/alladini-karahvin-kuldne-13l-4-joogiklaasi" />} exact />;
                                            <Route path="/tootekategooria/kingitused-lilleorust/" element={<Redirect to="/tooted?category=15" />} exact />;
                                            <Route path="/tootekategooria/kingitused-lilleorust/valitud-kingitused/" element={<Redirect to="/tooted?category=15" />} exact />;
                                            <Route path="/tootekategooria/jooga/" element={<Redirect to="/tooted?category=6" />} exact />;
                                            <Route path="/jooga/jooga-3/" element={<Redirect to="/tooted?category=24" />} exact />;
                                            <Route path="/tootekategooria/jooga/syvajooga/" element={<Redirect to="/tooted?category=25" />} exact />;
                                            <Route path="/tootekategooria/jooga/syvajooga/jooga-suvajooga/" element={<Redirect to="/tooted?category=52" />} exact />;
                                            <Route path="/tootekategooria/jooga/syvajooga/jooga-suvajooga/pildid/" element={<Redirect to="/tooted?category=25" />} exact />;
                                            <Route path="/tootekategooria/jooga/syvajooga/sisemine-jooga/" element={<Redirect to="/tooted?category=25" />} exact />;
                                            <Route path="/tootekategooria/raamatud/" element={<Redirect to="/tooted?category=1" />} exact />;
                                            <Route path="/tootekategooria/raamatud/ingliskeelsed-raamatud/" element={<Redirect to="/tooted?category=23" />} exact />;
                                            <Route path="/tootekategooria/teed/" element={<Redirect to="/tooted?category=2" />} exact />;
                                            <Route path="/tootekategooria/teed/lilleoru-teesegud/" element={<Redirect to="/tooted?category=60" />} exact />;
                                            <Route path="/tootekategooria/teed/teesegud/" element={<Redirect to="/tooted?category=38" />} exact />;
                                            <Route path="/tootekategooria/mesindus/" element={<Redirect to="/tooted?category=16" />} exact />;
                                            <Route path="/tootekategooria/mesindus/mesindus-2/" element={<Redirect to="/tooted?category=18" />} exact />;
                                            <Route path="/tootekategooria/mesindus/punkti-meed/" element={<Redirect to="/tooted?category=19" />} exact />;
                                            <Route path="/tootekategooria/mesindus/puidust-kinkekastid/" element={<Redirect to="/tooted?category=20" />} exact />;
                                            <Route path="/tootekategooria/mesindus/kinkekastid/" element={<Redirect to="/tooted?category=105" />} exact />;
                                            <Route path="/tootekategooria/hudrolaadid/" element={<Redirect to="/tooted?category=69" />} exact />;
                                            <Route path="/tootekategooria/aroomid-ja-viirukid/" element={<Redirect to="/tooted?category=9" />} exact />;
                                            <Route path="/tootekategooria/tervisetooted/" element={<Redirect to="/tooted?category=7" />} exact />;
                                            <Route path="/tootekategooria/looduskosmeetika/" element={<Redirect to="/tooted?category=5" />} exact />;
                                            <Route path="/tootekategooria/veenoud/" element={<Redirect to="/tooted?category=4" />} exact />;
                                            <Route path="/tootekategooria/veepudelid/" element={<Redirect to="/tooted?category=28" />} exact />;
                                            <Route path="/tootekategooria/keraamilised-tassid/" element={<Redirect to="/tooted?category=3" />} exact />;
                                            <Route path="/tootekategooria/ehted/" element={<Redirect to="/et/tooted?category=8" />} exact />;
                                            <Route path="/tootekategooria/ehted/puidust-ehted/" element={<Redirect to="/tooted?category=128" />} exact />;
                                            <Route path="/tootekategooria/ehted/malad/" element={<Redirect to="/tooted?category=48" />} exact />;
                                            <Route path="/tootekategooria/ehted/ehted-2/" element={<Redirect to="/tooted?category=47" />} exact />;
                                            <Route path="/tootekategooria/markmikud/" element={<Redirect to="/tooted?category=10" />} exact />;
                                            <Route path="/tootekategooria/markmikud/kristiina-pollu/" element={<Redirect to="/tooted?category=26" />} exact />;
                                            <Route path="/tootekategooria/riided/" element={<Redirect to="/tooted?category=14" />} exact />;
                                            <Route path="/tootekategooria/riided/ishwara-pildiga-tooted/" element={<Redirect to="/tooted?category=93" />} exact />;
                                            <Route path="/tootekategooria/riided/dakini/" element={<Redirect to="/tooted?category=14" />} exact />;
                                            <Route path="/tootekategooria/kunst/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/avatar/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/ahti-sepsivart/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/kadri-kangilaski/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/kaie-kal/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/kaija-kesa/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/tootekategooria/kunst/toomas-tonissoo/" element={<Redirect to="/tooted?category=12" />} exact />;
                                            <Route path="/kauba-tagastamine/" element={<Redirect to="/tingimused" />} exact />;
                                            <Route path="/kontakt/" element={<Redirect to="/" />} exact />;
                                            <Route path="/minu-konto/" element={<Redirect to="/" />} exact />;
                                            <Route path="/ostutingimused/" element={<Redirect to="/tingimused" />} exact />;
                                            <Route path="/pood" element={<Redirect to="/tooted" />} exact />;
                                            <Route path="/tarnetingimused/" element={<Redirect to="/tingimused" />} exact />;
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}